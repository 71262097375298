/* eslint no-console: 0 */
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import App from '../app.vue'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {

  let element = document.getElementById("hello");

  if (element != null) {
    const app = new Vue({
      el: element,
      data: () => {
        return {
          message: "Can you say hello?"
        }
      },
      components: {App}
    })
  }
})
