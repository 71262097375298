/* eslint no-console: 0 */
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import App from '../app.vue'
import Vuex from 'vuex'
import _ from 'lodash'
import axios from 'axios'

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;

Vue.use(Vuex)

// TODO update with scalable Vuex option
// import { store } from './store/store';

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {

  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common['Accept'] = 'application/json'

  // storage for saved data
  var storageKey = 'content'
  var api = {
    load: function (enrollmentId, noteId) {
      return axios
        .get("/enrollments/" + enrollmentId + "/notes/" + noteId)
        .then(function (response) {
          // console.log("loading notes: " + JSON.stringify(response.data.notes.message))
          return response.data.notes.message
        }, error => {
          console.log(error.response.data);
          return "";
        })
        .catch(function (error) {
          console.log(error);
          return "";
        });

    },
    save: _.debounce(function (enrollmentId, noteId, content, callback) {
      let url = "/enrollments/" + enrollmentId + "/notes/" + noteId
      // console.log("save url: " + url)
      let params = {
        note: {
          message: content
        }
      }
      axios
        .put(url, params)
        .then((response) => {
          // console.log(response.data.notes);
        }, error => {
          console.log(error.response.data);
        })
        .catch(error => {
          console.log("An error occurred: " + error);
        });

      // local storage example from tutorial
      // window.localStorage.setItem(storageKey, JSON.stringify(content))
      callback()
    }, 1000, { maxWait: 3000 })
  }

  // for autosave
  const autosaverPlugin = function (store) {
    let enrollmentId = store.state.enrollmentId
    let noteId = store.state.noteId

    store.subscribe(function (mutation, state) {
      if (mutation.type === 'UPDATE_CONTENT') {
        store.commit('SET_SAVE_STATUS', 'Saving...')
        api.save(state.enrollmentId, state.noteId, mutation.payload, function () {
          store.commit('SET_SAVE_STATUS', 'Notes Saved')
        })
        return
      }
    })
  }

  // Vuex store
  let store = new Vuex.Store({
    state: {
      content: '',
      saveStatus: '',
      enrollmentId: '',
      noteId: ''
    },
    mutations: {
      'LOAD_CONTENT': function (state, initialContent) {
        state.content = initialContent
      },
      'SET_SAVE_STATUS': function (state, newSaveStatus) {
        state.saveStatus = newSaveStatus
      },
      'SET_ENROLLMENT_ID': function(state, enrollmentId) {
        state.enrollmentId = enrollmentId;
      },
      'SET_NOTE_ID': function(state, noteId) {
        state.noteId = noteId;
      },
      'UPDATE_CONTENT': function (state, newContent) {
        state.content = newContent
      }
    },
    plugins: [autosaverPlugin]
  })

  Vue.component('saving-indicator', {
    template: '<div>{{ saveStatus }}</div>',
    computed: {
      saveStatus: function () {
        return this.$store.state.saveStatus
      }
    }
  })

  // Vue component for text entry
  const TextEntry = Vue.component('text-entry', {
    // TODO get template working
    // template: '#text-entry-template',
    template: '' +
      '<div class="row">' +
      '  <div class="col-md-12">' +
      '    <textarea class="note-message" v-model="content" @keyup="registerChange"></textarea>' +
      '    <div class="loader" v-if="displaySpinner"></div>' +
      '    <saving-indicator></saving-indicator>' +
      '  </div>' +
      '</div>',
    props: [
      'enrollmentId', 'noteId'
    ],
    data: function () {
      return {
        content: '',
        local_state: 'starting'
      }
    },
    mounted: function() {
      this.$store.commit('SET_ENROLLMENT_ID', this.enrollmentId)
      this.$store.commit('SET_NOTE_ID', this.noteId)

      this.loadNotes()
    },
    computed: {
      displaySpinner: function() {
        return this.local_state === 'starting'
      }
    },
    methods: {
      notesFailed: function(data) {
        console.log("notes failed: " + data)
      },
      notesOk: function(data) {
        this.local_state = 'loaded'
        this.content = data
        this.$store.commit('LOAD_CONTENT', data)
      },
      loadNotes: function() {
        api.load(this.enrollmentId, this.noteId)
          .then(this.notesOk.bind(this))
          .catch(this.notesFailed.bind(this));
      },
      registerChange: function () {
        this.$store.commit('UPDATE_CONTENT', this.content)
      }
    }
  })

  let element = document.getElementById("autosave-textfield");

  if (element != null) {
    const AutosaveApp = new Vue({
      el: element,
      store: store,
    })
  }
})
