/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("chartkick")
require("chart.js")

// Vue apps
require("packs/hello_vue")
require("packs/autosave_textfield")

// custom coffeescript files
require("packs/commitments")
// require("packs/datepicker-format")
require("packs/payment_forms")
require("packs/session_update")

import "@fortawesome/fontawesome-free/js/all";

// Load Images
require.context('../images', true)

// import all stylesheets
import '../stylesheets/application.scss'

// need for .js.erb files to work
window.jQuery = window.$ = require('jquery')

document.addEventListener("turbolinks:load", function() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
})

jQuery(document).ready(function($) {
  // trigger pagination
  $(".pagination form select").change(function(e) {
    $(this).parent("form").submit();
  });
});

console.log('Hello World from Webpacker')
